import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import { popAlert } from 'src/store';

const AppAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.app.alerts[0]);
  const [show, setShow] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const handleAlertAction = async (cb) => {
    if (cb) {
      setDisabled(true);
      await cb();
      setDisabled(false);
    }
    setShow(false);
  };
  const handleAlertModalClosed = () => {
    dispatch(popAlert());
    setShow(true);
  };

  if (!alert) {
    return null;
  }

  return (
    <CModal
      show={show}
      color={alert.type || 'danger'}
      onClosed={handleAlertModalClosed}
      closeOnBackdrop={!disabled}
    >
      <CModalHeader closeButton={!disabled}>{alert.title}</CModalHeader>
      <CModalBody className="text-center">
        <h5>{alert.description}</h5>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={disabled}
          variant="outline"
          color={alert.type || 'danger'}
          onClick={() => handleAlertAction(alert.onConfirm)}
        >
          Confirmar
        </CButton>
        <CButton
          disabled={disabled}
          color="light"
          onClick={() => handleAlertAction(alert.onCancel)}
        >
          Cancelar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AppAlert;
